import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/ListLayout"
import Post from "../components/Post"
import PaginationLinks from "../components/PaginationLinks"

const postList = props => {
  const posts = props.data.allMarkdownRemark.edges
  const {currentPage, numberOfPages} = props.pageContext
  return (
    <Layout>
      {posts.map(edge => {
        return (
          <Post
            key={edge.node.id}
            title={edge.node.frontmatter.title}
            date={edge.node.frontmatter.date}
            author={edge.node.frontmatter.author}
            slug={edge.node.fields.slug}
            fluid={edge.node.frontmatter?.image?.childImageSharp?.fluid}
            tags={edge.node.frontmatter.tags}
            showImg={false}
          />
        )
      })}
      <PaginationLinks
        currentPage={currentPage}
        numberOfPages={numberOfPages}
      />
    </Layout>
  )
}
export const postListQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {fields: {draft: {eq: false}}}
      sort: {fields: [frontmatter___date], order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD ddd")
            author
            tags
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default postList
